<template>
    <div id="container">
        <clone-tab :bgcShow="bgcShow" class="cloneBtns"></clone-tab>
        <baidu-map class='bm-view' @ready='init' :center="mapData.center" :zoom="mapData.zoom"></baidu-map>
        <div class="messageBtn" @click="baiduMap">点击导航</div>
    </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
export default {
  data() {
    return {
        //地图数据
            mapData: {
                //中心坐标
                center: { lng: 0, lat: 0 },
                //缩放级别
                zoom: 13
            },
            //BMap类
            BMap: null,
            //地图对象
            map: null,
            bgcShow:true,
            queryList:null
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "地图";
    this.queryList = this.$route.query
  },
  methods: {
    baiduMap() {
      console.log(this.queryList.address);
      // return false
      window.location = `http://api.map.baidu.com/geocoder?address=${
        this.queryList.address
      }&output=html&src=webapp.baidu.openAPIdemo`;
    },
    async init({ BMap, map }) {
      //保存百度地图类
      this.BMap = BMap;
      //保存地图对象
      this.map = map;
    //   let xy = coordinateTrans.WGS84toBD([114.35567, 30.52922]);
      // let point = new BMap.Point(114.3016729410009,30.662993889353807); //设置城市经纬度坐标
      console.log(this.queryList.lat,this.queryList.lng)
      let point = new BMap.Point(this.queryList.lng,this.queryList.lat); //设置城市经纬度坐标

      map.centerAndZoom(point, 15); //
      map.setCurrentCity("武汉"); // 设置地图显示的城市 此项是必须设置的
      map.enableScrollWheelZoom(true); //鼠标滚动缩放
      let marker = new BMap.Marker(point);
      map.addOverlay(marker)
    }
  }
};
</script>
<style>

#container{
    width:100%;height:100vh;
    position: relative;
}
.bm-view{
    height:100vh
}
.cloneBtns{
    position:absolute;
    top: 20px;
    left: 20px;
    z-index: 9999
}
.messageBtn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  width: 300px;
  height: 60px;
  background: #4b93ff;
  box-shadow: 0px 0px 10px rgba(75, 147, 255, 0.5);
  opacity: 1;
  border-radius: 0px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  color: #fff;
}
</style>
